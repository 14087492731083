<template>
  <div style="min-height: 500px" v-loading="loading">
    <div class="top">
      <el-button class="close" plain @click="$router.push('/')">关闭</el-button>
      <h4>投诉建议</h4>
    </div>
    <el-input
      class="diy-input"
      v-model="title"
      placeholder="请输入建议标题"
      :maxlength="50"
      show-word-limit
    ></el-input>
    <el-input
      class="diy-textarea"
      v-model="content"
      type="textarea"
      :rows="10"
      :maxlength="300"
      show-word-limit
      placeholder="请输入您的建议"
    ></el-input>
    <el-input
      class="diy-input"
      v-model="contact"
      placeholder="称呼"
      :maxlength="50"
      show-word-limit
    ></el-input>
    <el-input
      class="diy-input"
      v-model="phone"
      placeholder="请输入您的手机号"
    ></el-input>
    <el-input
      class="diy-input"
      v-model="email"
      placeholder="请输入您的邮箱"
    ></el-input>
    <div class="submit"><com-button @click="submit">提交</com-button></div>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
import { validatenull, isEmail, isMobile } from "@/util/validate";
export default {
  components: {
    comButton,
  },
  data() {
    return {
      title: "",
      content: "",
      phone: "",
      contact: "",
      email: "",
      loading: false,
    };
  },
  methods: {
    submit() {
      if (validatenull(this.content)) {
        this.$message.error("请输入投诉建议");
        return;
      }
      console.log(isMobile(this.phone));
      if (this.phone.length !== 0 && !isMobile(this.phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (this.email.length !== 0 && !isEmail(this.email)) {
        this.$message.error("请输入正确的邮箱");
        return;
      }
      this.loading = true;
      this.$api
        .sendSubmitComplaint({
          title: this.title,
          content: this.content,
          phone: this.phone,
          email: this.email,
          contact: this.contact,
        })
        .then((resolve) => {
          this.loading = false;
          if (resolve.code === 200) {
            this.$message.success("提交成功");
            Object.assign(this._data, this.$options.data());
          }
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.css";
@import "../../style/form.scss";
.diy-input,
.diy-textarea {
  margin-top: 20px;
}
.submit {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}
</style>
